require('./bootstrap');
// Require for development
require('./gsap-business-green/minified/GSDevTools.min');

// Navigation
$('.jsNavigationOpen').on('click', function() {
    $('body').addClass('menuOpen');
    $('.c-menuBackdrop').addClass('menuOpen');
});

$('.jsNavigationClose').on('click', function() {
    $('body').removeClass('menuOpen');
    $('.c-menuBackdrop').removeClass('menuOpen');
});

$('.c-menuBackdrop').on('click', function() {
    if($( this ).hasClass('menuOpen')) {
        $('.c-navContent').collapse('toggle');
        $('body').removeClass('menuOpen');
        $('.c-menuBackdrop').removeClass('menuOpen');
    }
});


// Activate GSAP Development Tools
$( document ).ready(function() {
    if($('body').hasClass('debugTimeline')) {
        GSDevTools.create();
    }
});

//console.log(navigator.hardwareConcurrency);

// Popover Help
$('.jsPopoverHelp').popover('show');

$('body').on('click', function() {
    $('.jsPopoverHelp').popover('hide');
});

$('#scene').on('touchstart', function() {
    $('.jsPopoverHelp').popover('hide');
});


